@font-face
	font-family: "svgfont"
	src: url('./../fonts/svgfont.woff2') format('woff2'), url('./../fonts/svgfont.woff') format('woff'), url('./../fonts/svgfont.eot'), url('./../fonts/svgfont.eot?#iefix') format('embedded-opentype'), url('./../fonts/svgfont.ttf') format('truetype')
	font-weight: normal
	font-style: normal
	font-display: swap

$icon-bag: "\EA01"
$icon-checkmark: "\EA02"
$icon-clock: "\EA03"
$icon-close: "\EA04"
$icon-dropdown: "\EA05"
$icon-mail: "\EA06"
$icon-map: "\EA07"
$icon-minus: "\EA08"
$icon-phone: "\EA09"
$icon-plus: "\EA0A"
$icon-search: "\EA0B"
$icon-user: "\EA0C"
$icon-eye-off: "\EA10"
$icon-eye: "\EA11"
$icon-settings: "\EA12"
$icon-articles: "\EA13"
$icon-chevron-up: "\EA14"
$icon-exit: "\EA15"
$icon-login: "\EA16"
$icon-chevron-left: "\EA17"
$icon-chevron-right: "\EA18"
$icon-play: "\EA19"
$icon-clipboard: "\EA20"
$icon-truck: "\EA21"
$icon-bookmark: "\EA22"
$icon-chart: "\EA23"
$icon-folder: "\EA24"
$icon-archive: "\EA25"
$icon-download: "\EA26"
$icon-card: "\EA27"
$icon-edit: "\EA28"
$icon-photo: "\EA29"
$icon-file-text: "\EA30"
$icon-clear-filter: "\EA31"
$icon-date: "\EA32"
$icon-back: "\EA33"
$icon-bar-chart: "\EA34"
$icon-check: "\EA35"
$icon-refresh: "\EA36"
$icon-move: "\EA37"
$icon-hint: "\EA38"
$icon-error: "\EA39"
$icon-print: "\EA40"
$icon-settings-2: "\EA41"
$icon-save: "\EA42"
$icon-x-circle: "\EA43"
$icon-log-in: "\EA44"
$icon-catalog: "\EA45"
$icon-file-pdf: "\EA46"
$icon-loop: "\EA47"
$icon-users: "\EA48"
$icon-edit2: "\EA49"
$icon-box: "\EA50"
$icon-shuffle: "\EA51"


%icon
	font-family: "svgfont"
	font-style: normal
	font-weight: normal
	text-rendering: auto
	speak: none
	line-height: 1
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

.icon, [class^="icon-"], [class*=" icon-"]
	@extend %icon
	display: inline-block

.icon-bag:before
	content: $icon-bag
.icon-checkmark:before
	content: $icon-checkmark
.icon-clock:before
	content: $icon-clock
.icon-close:before
	content: $icon-close
.icon-dropdown:before
	content: $icon-dropdown
.icon-mail:before
	content: $icon-mail
.icon-map:before
	content: $icon-map
.icon-minus:before
	content: $icon-minus
.icon-phone:before
	content: $icon-phone
.icon-plus:before
	content: $icon-plus
.icon-search:before
	content: $icon-search
.icon-user:before
	content: $icon-user
.icon-eye-off:before
	content: $icon-eye-off
.icon-eye:before
	content: $icon-eye
.icon-settings:before
	content: $icon-settings
.icon-articles:before
	content: $icon-articles
.icon-chevron-up:before
	content: $icon-chevron-up
.icon-exit:before
	content: $icon-exit
.icon-login:before
	content: $icon-login
.icon-chevron-left:before
	content: $icon-chevron-left
.icon-chevron-right:before
	content: $icon-chevron-right
.icon-play:before
	content: $icon-play
.icon-clipboard:before
	content: $icon-clipboard
.icon-truck:before
	content: $icon-truck
.icon-bookmark:before
	content: $icon-bookmark
.icon-chart:before
	content: $icon-chart
.icon-folder:before
	content: $icon-folder
.icon-archive:before
	content: $icon-archive
.icon-download:before
	content: $icon-download
.icon-card:before
	content: $icon-card
.icon-edit:before
	content: $icon-edit
.icon-photo:before
	content: $icon-photo
.icon-file-text:before
	content: $icon-file-text
.icon-clear-filter:before
	content: $icon-clear-filter
.icon-date:before
	content: $icon-date
.icon-back:before
	content: $icon-back
.icon-bar-chart:before
	content: $icon-bar-chart
.icon-check:before
	content: $icon-check
.icon-refresh:before
	content: $icon-refresh
.icon-move:before
	content: $icon-move
.icon-hint:before
	content: $icon-hint
.icon-error:before
	content: $icon-error
.icon-print:before
	content: $icon-print
.icon-settings-2:before
	content: $icon-settings-2
.icon-save:before
	content: $icon-save
.icon-x-circle:before
	content: $icon-x-circle
.icon-log-in:before
	content: $icon-log-in
.icon-catalog:before
	content: $icon-catalog
.icon-file-pdf:before
	content: $icon-file-pdf
.icon-loop:before
	content: $icon-loop
.icon-users:before
	content: $icon-users
.icon-edit2:before
	content: $icon-edit2
.icon-box:before
	content: $icon-box
.icon-shuffle:before
	content: $icon-shuffle
